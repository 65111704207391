// As SDL no longer supplies 'apc-login-and-minicart.js'
// We need to make sure we have all the necessary code that we need from that file
// There is a code below, copied and adjusted from 'apc-login-and-minicart.js
(function() {
    var header = document.querySelector('.js-header-apc'); // '.js-header-apc' provided to us by SDL
    var headerDataSets = header.dataset;

    function isAttributeExists(attribute) {
        return Object.values(headerDataSets).indexOf(attribute) > -1;
    }

    window.gotoCart = function(event) {
        if (!isAttributeExists(headerDataSets.apcAtgcarturl)) {
            return console.warn('data-attribute has not been provided');
        }

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        window.location = headerDataSets.apcAtgcarturl;
    };

    window.gotoRegister = function(event) {
        if (!isAttributeExists(headerDataSets.apcRegisterurl)) {
            return console.warn('data-attribute has not been provided');
        }

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        window.location = headerDataSets.apcRegisterurl;
    };

    window.gotoLogin = function(event) {
        if (!isAttributeExists(headerDataSets.apcIdmsloginurl)) {
            return console.warn('data-attribute has not been provided');
        }

        if (event && event.preventDefault) {
            event.preventDefault();
        }

        if (window.param == true) {
            window.location = headerDataSets.apcIdmsloginurl;
        } else {
            window.location = headerDataSets.apcLoginurl;
        }
    };
})();
